/**
 * # Copy Text
 */
/**
 * ## Imports
 */
import React, { FC } from 'react';
import clsx from 'clsx';

/**
 * ## Types / Interfaces
 */
type CopyTextProps = {
    children: React.ReactNode;
    clientName: string;
    classList?: string;
}

/**
 * ## Component
 */
const CopyText: FC<CopyTextProps> = ({ children, clientName, classList }) => {
    const isInfinityClient = clientName === 'infinity';

    return (
        <div className={clsx(
            'copytext-page',
            {
                'copytext-page--infinity': isInfinityClient,
            },
            classList,
        )}
        >
            <div
                className={clsx(
                    'centerwrapper centerwrapper--very-narrow',
                    {
                        'centerwrapper--infinity': isInfinityClient,
                    },
                )}
            >
                {children}
            </div>
        </div>
    );
};

/**
 * ## Exports
 */
export default CopyText;
