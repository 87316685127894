/**
 * # Body Class Updater - Helper Function
 * This function switches the class on the body tag in case
 * the host name of the client would change. This way we can
 *
 * It also toggles a class from the HTML <body> tag when
 * the body needs to become unscrollable while the mobile Menu
 * is opened.
 */

/**
 * ## Imports
 */
import { useState, useEffect } from 'react';
// import { HostConfig } from '@/constants/hostConfigs';

/**
 * ## Types / Interfaces
 */
type BodyClassUpdaterProps = {
    // hostConfig: HostConfig;
    isOpened: boolean;
}

/**
 * ## Function
 */
// function BodyClassUpdater({ hostConfig, isOpened }: BodyClassUpdaterProps) {
const BodyClassUpdater = ({ isOpened }: BodyClassUpdaterProps) => {
    /* eslint-disable-next-line */
    const [currentBodyClass, setCurrentBodyClass] = useState('');

    useEffect(() => {
        const bodyTag = document.body;
        // const newHostBodyClass = hostConfig.property;
        const bodyToggleClass = 'body-frozen';

        /**
         * - Toggle Host Client's `<body>` class.
         */
        if (currentBodyClass) {
            bodyTag.classList.remove(currentBodyClass);
        }
        // bodyTag.classList.add(newHostBodyClass);

        // setCurrentBodyClass(newHostBodyClass);

        /**
         * - Toggle a frozen class when mobile navigation
         * is open.
         */
        if (isOpened) {
            bodyTag.classList.add(bodyToggleClass);
        } else {
            bodyTag.classList.remove(bodyToggleClass);
        }

    // }, [hostConfig, currentBodyClass, isOpened])
    }, [currentBodyClass, isOpened]);

    return null;
};

export default BodyClassUpdater;
