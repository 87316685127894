/**
 * # About Page Content
 */

/**
 * ## Imports
 */
import DecoBoxText from '../DecoBoxText/DecoBoxText';
import TempestMap from '../TempestMap/TempestMap';
import styles from './AboutPageContent.module.scss';

/**
 * ## Component
 */
const AboutPageContent = () => {
    return (
        <article className={styles['about-us']}>
            <div className={`${styles['ladyfingers']}`} />
            <div className={`${styles['about-us__intro']} container-center`}>
                <h2 className="headline-quinary">About Us</h2>
                <h3 className={styles['about-us__headline-inconsistent']}><span className="headline-sober">Company</span> Vision</h3>
                <p className="paragraph-lean">At Meteor Mobile, we’re revolutionizing how people communicate and live through a suite of innovative user-centric, utility-focused mobile apps.</p>
            </div>
            <DecoBoxText />
            <section className={styles['about-us__map-wrapper']}>
                <div className="container-center">
                    <h3 className={styles['about-us__headline-inconsistent']}>Our Team <br /><span className="headline-sober">around the globe</span></h3>
                    <p className="paragraph-lean">Our team of engineers and design experts is developing products in countries across the globe. Our offices include Germany, France, Singapore, Portugal, Mexico and the US. Interested in working with us?</p>
                </div>
                <TempestMap />
            </section>
        </article>
    );
};

/**
 * ## Exports
 */

export default AboutPageContent;