/**
 * # Not Found Page - 404
 */
/**
 * ## Imports
 */
import PageLayout from 'src/components/PageLayout/PageLayout';
import NotFound from 'src/components/NotFound/NotFound';
// import CookieConsentBanner from 'src/components/CookieConsentBanner/CookieConsentBanner';

const NotFoundPage = () => {
    return (
        <PageLayout>
            {/* <CookieConsentBanner /> */}
            <NotFound />
        </PageLayout>
    );
};

export default NotFoundPage;
