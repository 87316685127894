/**
 * # Phoner Page
 */

/**
 * ## Imports
 */
import PageLayout from 'src/components/PageLayout/PageLayout';
import CookieConsentBanner from 'src/components/CookieConsentBanner/CookieConsentBanner';
import PhonerPageContent from '../../components/PhonerPageContent/PhonerPageContent';
import '../../styles/base.scss';

/**
 * ## Component Output
 */
const PhonerPage = () => {
    return (
        <PageLayout>
            <CookieConsentBanner />
            <PhonerPageContent />
        </PageLayout>
    );
};

export default PhonerPage;
