/* eslint-disable jsx-a11y/no-static-element-interactions */
/**
 * # Testimonials
 */

/**
 * Imports
 */
import { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import TechradarLogo from 'src/images/infinity/logos/techradar.png';
import TechCrunchLogo from 'src/images/infinity/logos/techcrunch.png';
import EuroNewsLogo from 'src/images/infinity/logos/euronews.png';
import TechEuLogo from 'src/images/infinity/logos/techeu.png';
import GeeksforGeeksLogo from 'src/images/infinity/logos/geeksforgeeks.png';
import styles from './Testimonials.module.scss';
import slides from './slides';

const Testimonials: FC = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderWrapperRef = useRef(null);
    const intervalRef = useRef(null);
    const startXRef = useRef(0);
    const isDraggingRef = useRef(false);

    const handleSlide = (index) => () => {
        let currentIndex = index;

        if (index < 0) {
            currentIndex = slides.length - 1;
        } else if (index >= slides.length) {
            currentIndex = 0;
        }
        setCurrentSlide(currentIndex);
    };

    useEffect(() => {
        intervalRef.current = setInterval(() => {
            handleSlide(currentSlide + 1)();
        }, 60000);
        return () => clearInterval(intervalRef.current);
    }, [currentSlide]);

    const startDrag = (event) => {
        isDraggingRef.current = true;
        startXRef.current = event.type === 'touchstart' ? event.touches[0].clientX : event.clientX;
        clearInterval(intervalRef.current);
    };

    const onDrag = (event) => {
        if (!isDraggingRef.current) return;
        const currentX = event.type === 'touchmove' ? event.touches[0].clientX : event.clientX;
        const diffX = startXRef.current - currentX;
        sliderWrapperRef.current.style.transform = `translateX(-${(currentSlide * 100) + (diffX / window.innerWidth) * 100}%)`;
    };

    const endDrag = (event) => {
        if (!isDraggingRef.current) return;
        isDraggingRef.current = false;
        const endX = event.type === 'touchend' ? event.changedTouches[0].clientX : event.clientX;
        const diffX = startXRef.current - endX;
        if (Math.abs(diffX) > 50) {
            if (diffX > 0) {
                handleSlide(currentSlide + 1)();
            } else {
                handleSlide(currentSlide - 1)();
            }
        } else {
            handleSlide(currentSlide);
        }
        sliderWrapperRef.current.style.transform = `translateX(-${currentSlide * 100}%)`;
        intervalRef.current = setInterval(() => {
            handleSlide(currentSlide + 1)();
        }, 5000);
    };

    return (
        <div id="case-studies" className={clsx(styles['wrapper'])}>
            <div className={styles['slider']}>
                <div
                    className={styles['slides']}
                    ref={sliderWrapperRef}
                    onMouseDown={startDrag}
                    onMouseMove={onDrag}
                    onMouseUp={endDrag}
                    onMouseLeave={endDrag}
                    onTouchStart={startDrag}
                    onTouchMove={onDrag}
                    onTouchEnd={endDrag}
                    style={{ transform: `translateX(-${currentSlide * 100}%)` }}
                >
                    {slides.map((slide, index) => (
                        <div key={index} className={clsx(styles['slide'], { [styles['slide--active']]: index === currentSlide })}>
                            <div className={styles['slide--info']}>
                                <h3 className={styles['slide-headline-quinary']}>Testimonials</h3>
                                <p className={styles['slide-teaser-text']}>{slide.text}</p>
                                <div className={styles['client']}>
                                    <div className={styles['client-info']}>
                                        <p className={styles['client-name']}>{slide.author}</p>
                                        <p className={styles['client-role']}>{slide.position}</p>
                                    </div>
                                    <img alt={slide.alt} src={slide.logo} className={styles['client-logo']} />
                                </div>
                            </div>

                            <div className={styles['slide--card']}>
                                <div>
                                    <p className={styles['slide--card-title']}>The Situation</p>
                                    <p className={styles['slide--card-text']}>{slide.situation}</p>
                                </div>
                                <div>
                                    <p className={styles['slide--card-title']}>The Challenge</p>
                                    <p className={styles['slide--card-text']}>{slide.challenge}</p>
                                </div>
                                <div>
                                    <p className={styles['slide--card-title']}>The Solution</p>
                                    <p className={styles['slide--card-text']}>{slide.solution}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                <div className={styles['pagination']}>
                    {slides.map((_, index) => (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                        <span
                            key={index}
                            className={clsx(styles['dot'], { [styles['dot--active']]: index === currentSlide })}
                            onClick={handleSlide(index)}
                        />
                    ))}
                </div>
            </div>

            <div className={styles['companies']}>
                <p className={styles['companies-quinary']}>As seen in</p>
                <div className={styles['companies--list']}>
                    <div>
                        <a href="https://www.techradar.com/pro/ecosia-launches-plant-friendly-cross-platform-browser" rel="noopener noreferrer" target="_blank">
                            <img alt="techradar logo" src={TechradarLogo} />
                        </a>
                    </div>
                    <div>
                        <a href="https://techcrunch.com/2024/04/22/ecosia-launches-a-cross-platform-browser-starts-an-affiliate-link-program/" rel="noopener noreferrer" target="_blank">
                            <img alt="TechCrunch logo" src={TechCrunchLogo} />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.euronews.com/next/2024/04/23/after-its-tree-planting-search-engine-ecosia-has-launched-a-eco-friendly-web-browser" rel="noopener noreferrer" target="_blank">
                            <img alt="euronews logo" src={EuroNewsLogo} />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.techradar.com/pro/ecosia-launches-plant-friendly-cross-platform-browser" rel="noopener noreferrer" target="_blank">
                            <img alt="tech.eu logo" src={TechEuLogo} />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.geeksforgeeks.org/ecosia-launches-plant-friendly-cross-platform-browser/" rel="noopener noreferrer" target="_blank">
                            <img alt="Geeksforgeeks logo" src={GeeksforGeeksLogo} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;