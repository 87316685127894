/**
 * # Cookie Consent Banner 🍪
 */
/**
 * ## Imports
 */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { hasUserAcceptedCookies, setUserAcceptedCookies, removeUserCookieAcceptance } from 'src/helpers/cookieConsentHelper';
import ButtonStandard from '../ButtonStandard/ButtonStandard';
import ButtonClose from '../ButtonClose/ButtonClose';
import styles from './CookieConsentBanner.module.scss';

/**
 * ## Types / Interface
 */

/**
 * ## JSX Output
 */
const CookieConsentBanner = () => {
    const [showBanner, setShowBanner] = useState<boolean>(false);

    useEffect(() => {
        const userAccepted = hasUserAcceptedCookies();
        setShowBanner(!userAccepted);
    }, []);

    const handleAcceptClick = () => {
        setUserAcceptedCookies();
        setShowBanner(false);
    };

    const handleCloseClick = () => {
        removeUserCookieAcceptance();
        setShowBanner(false);
    };

    if (!showBanner) {
        return null;
    }

    const cookiePolicyText = 'By clicking "Accept all cookies" you agree to the storing of cookies on your device to enhance site navigation, analyse site usage and assist in our marketing efforts. ';

    const copyTextMarkup = () => (
        <p className={styles['copytext']}>
            {cookiePolicyText}
            <Link to="/cookie-policy" className="copytext-link">Cookie Policy</Link>
        </p>
    );

    return (
        <section className={styles['banner']}>
            <div className={styles['banner__body']}>
                <h2 className="sr-only">Do You Accept Our Cookies?</h2>
                {copyTextMarkup()}
                <ul className="button-list">
                    <li>
                        <ButtonStandard label="Accept all cookies" onClick={handleAcceptClick} classList="btn-accept" />
                    </li>
                    <li>
                        <ButtonClose
                            classList="button-close-cookies"
                            onClick={handleCloseClick}
                            title="Refuse cookies and close banner"
                        />
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default CookieConsentBanner;