/**
 * # Client Config
 */
export const CLIENT = {
    meteor: {
        name: 'Meteor',
        hasNavigationRoutes: true,
        withFooter: true, // Just a demo
    },
    infinity: {
        name: 'Infinity',
        hasNavigationRoutes: false,
    },
};

const defaultClient = {
    name: 'Meteor',
};

const selectedClient = CLIENT[process.env.REACT_APP_ENV] || defaultClient;

export const ClientName = selectedClient.name.toLowerCase();