/**
 * # Custom Browser Section
 */

/**
 * Imports
 */
import clsx from 'clsx';
import extensionsSrc from 'src/images/infinity/illustrations/features-extensions.png';
import newTabSrc from 'src/images/infinity/illustrations/features-new-tab.png';
import defaultSearchSrc from 'src/images/infinity/illustrations/features-deafult-search.png';
import onboardingSrc from 'src/images/infinity/illustrations/features-onboarding.png';
import engagementSrc from 'src/images/infinity/illustrations/features-engagement.png';
import themeSrc from 'src/images/infinity/illustrations/features-theme.png';
import FeatureCard from '../FeatureCard/FeatureCard';
import styles from './CustomBrowserSection.module.scss';

const CustomBrowserSection = () => {
    return (
        <div className={styles['wrapper']}>
            <div className={styles['body']}>
                <h1 className={styles['headline-quinary']}>Features</h1>
                <h2 className={styles['features-headline-main']}>Build a custom browser your way</h2>

                {/* Desktop */}
                <div className={clsx(styles['cards'], styles['cards--desktop'])}>
                    <div className={styles['column']}>
                        <FeatureCard
                            title="Pré-installed extensions"
                            description="Seamlessly integrate preferred capabilities to complement your custom browser"
                            imgSrc={extensionsSrc}
                            classList={styles['extensions']}
                        />
                        <FeatureCard
                            title="Customize the new tab"
                            description="Greet users and encourage engagement with a customized new tab page including branding, site shortcuts, widgets and more"
                            imgSrc={newTabSrc}
                            classList={clsx(styles['new-tab'], styles['pink-border'])}
                        />
                    </div>

                    <div className={styles['column']}>
                        <FeatureCard
                            title="Choose your default search engine"
                            description="Choose and configure your preferred search engine to be the default and monetize searches"
                            imgSrc={defaultSearchSrc}
                            classList={clsx(styles['default-search'], styles['pink-border'])}
                        />
                        <FeatureCard
                            title="Personalized Onboarding"
                            description="Guide new users through an interactive, branded tutorial showcasing unique capabilities and value right away"
                            imgSrc={onboardingSrc}
                            classList={styles['onboarding']}
                        />
                    </div>

                    <div className={styles['column']}>
                        <FeatureCard
                            title="Track user engagement"
                            description="Monitor engagement, anonymized usage statistics and more to optimize ongoing development"
                            imgSrc={engagementSrc}
                            classList={styles['engagement']}
                        />
                        <FeatureCard
                            title="Branded browser theme"
                            description="Integrate company colors, logos and imagery into a cohesive, branded theme to reinforce identity throughout the experience."
                            imgSrc={themeSrc}
                            classList={clsx(styles['theme'], styles['pink-border'])}
                        />
                    </div>
                </div>

                {/* Mobile */}
                <div className={clsx(styles['cards'], styles['cards--mobile'])}>
                    <div className={styles['column']}>
                        <FeatureCard
                            title="Pré-installed extensions"
                            description="Seamlessly integrate preferred capabilities to complement your custom browser"
                            imgSrc={extensionsSrc}
                            classList={styles['extensions']}
                        />
                        <FeatureCard
                            title="Choose your default search engine"
                            description="Choose and configure your preferred search engine to be the default and monetize searches"
                            imgSrc={defaultSearchSrc}
                            classList={clsx(styles['default-search'], styles['pink-border'])}
                        />
                    </div>

                    <div className={styles['column']}>
                        <FeatureCard
                            title="Track user engagement"
                            description="Monitor engagement, anonymized usage statistics and more to optimize ongoing development"
                            imgSrc={engagementSrc}
                            classList={styles['engagement']}
                        />
                        <FeatureCard
                            title="Customize the new tab"
                            description="Greet users and encourage engagement with a customized new tab page including branding, site shortcuts, widgets and more"
                            imgSrc={newTabSrc}
                            classList={clsx(styles['new-tab'], styles['pink-border'])}
                        />
                    </div>

                    <div className={styles['column']}>
                        <FeatureCard
                            title="Personalized Onboarding"
                            description="Guide new users through an interactive, branded tutorial showcasing unique capabilities and value right away"
                            imgSrc={onboardingSrc}
                            classList={styles['onboarding']}
                        />
                        <FeatureCard
                            title="Branded browser theme"
                            description="Integrate company colors, logos and imagery into a cohesive, branded theme to reinforce identity throughout the experience."
                            imgSrc={themeSrc}
                            classList={clsx(styles['theme'], styles['pink-border'], styles['last-mobile-card'])}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomBrowserSection;