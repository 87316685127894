/**
 * # Button Standard
 */
/**
 * ## Imports
 */
import React from 'react';
import clsx, { ClassValue } from 'clsx';
import styles from './ButtonStandard.module.scss';

/**
 * ## Types / Interfaces
 */
type ButtonStandardProps = {
    label: string;
    onClick: () => void;
    classList?: string;
}

/**
 * ## JSX Output
 */
const ButtonStandard: React.FC<ButtonStandardProps> = ({ label, onClick, classList }) => {
    const buttonClassName: ClassValue = clsx(
        [styles['button']],
        classList,
    );
    return (
        <button
            type="button"
            onClick={onClick}
            className={buttonClassName}
        >
            {label}
        </button>
    );
};

export default ButtonStandard;