/**
 * # CtaButton Phoner
 * - Single source of truth for CTA buttons that are specific to Phoner downloads.
 */

/**
 * ## Imports
 */
import React from 'react';
import CtaButton from '../CtaButton/CtaButton';

/**
 * ## Types / Interfaces
 */
type CtaButtonPhonerProps = {
    textLabel?: string;
    classList?: string;
}

/**
 * ## Component
 */
const CtaButtonPhoner: React.FC<CtaButtonPhonerProps> = ({ textLabel = 'Try Phoner Now', classList }) => {
    return (
        <CtaButton
            link="https://apps.apple.com/us/app/phoner-second-phone-number/id1050267310?mt=8"
            textLabel={textLabel}
            classList={classList}
        />
    );
};

/**
 * ## Exports
 */
export default CtaButtonPhoner;