/**
 * # Set Favicon for Each Client
 */

/**
 * ## Helper Function
 */
export const setFavicon = ({ client }: {client: string}) => {
    const favicon: HTMLLinkElement | null = document.querySelector('link[rel="icon"]');
    const isMeteorClient = client === 'meteor';
    const isInfinityClient = client === 'infinity';

    if (isMeteorClient) {
        favicon.href = 'meteor/favicon.ico';
    } else if (isInfinityClient) {
        favicon.href = 'infinity/favicon.ico';
    } else {
        favicon.href = '/favicon.ico';
    }

    /**
     * ### Apple Touch Icons 🍎📱
     */
    const appleTouchIcons = {
        'apple-touch-icon-76x76': 'meteor/apple-touch-icon-76x76.png',
        'apple-touch-icon-120x120': 'meteor/apple-touch-icon-120x120.png',
        'apple-touch-icon-152x152': 'meteor/apple-touch-icon-152x152.png',
        'apple-touch-icon-180x180': 'meteor/apple-touch-icon-180x180.png',
        'apple-touch-icon-default': 'meteor/apple-touch-icon.png',
    };

    if (isInfinityClient) {
        appleTouchIcons['apple-touch-icon-76x76'] = 'infinity/apple-touch-icon-76x76.png';
        appleTouchIcons['apple-touch-icon-120x120'] = 'infinity/apple-touch-icon-120x120.png';
        appleTouchIcons['apple-touch-icon-152x152'] = 'infinity/apple-touch-icon-152x152.png';
        appleTouchIcons['apple-touch-icon-180x180'] = 'infinity/apple-touch-icon-180x180.png';
        appleTouchIcons['apple-touch-icon-default'] = 'infinity/apple-touch-icon.png';
    }

    Object.keys(appleTouchIcons).forEach((key) => {
        const link = document.getElementById(key);
        if (link instanceof HTMLLinkElement) {
            link.href = appleTouchIcons[key];
        }
    });
};
