/**
 * # About Page
 */

/**
 * ## Imports
 */
import PageLayout from 'src/components/PageLayout/PageLayout';
import CookieConsentBanner from 'src/components/CookieConsentBanner/CookieConsentBanner';
import { ClientName } from 'src/client/clientConfig';
import AboutPageContent from '../../components/AboutPageContent/AboutPageContent';
import InfinityAboutPageContent from '../../components/AboutPageContent/InfinityAboutPageContent';
import '../../styles/base.scss';

/**
 * ## Component Output
 */
const AboutPage = () => {
    const isInfinityClient = ClientName === 'infinity';

    return (
        <PageLayout>
            {!isInfinityClient ? <CookieConsentBanner /> : null}
            {isInfinityClient ? <InfinityAboutPageContent /> : <AboutPageContent />}
        </PageLayout>
    );
};

export default AboutPage;
