/**
 * # Mobile Menu
 */

/**
 * ## Imports
 */
import React, { useRef, useEffect, useState } from 'react';
import clsx, { ClassValue } from 'clsx';
import CategoryList from 'src/@types/categoryList';

import { ClientName } from 'src/client/clientConfig';
import ChevronDownIcon from 'src/images/infinity/icons/chevron-down.png';
import styles from './MobileMenu.module.scss';
import CtaButton from '../CtaButton/CtaButton';

/**
 * ## Types / Interfaces
 */
type MoblieMenuProps = {
    categoryList: CategoryList[];
    hidden: boolean;
    onClick: () => void;
}

/**
 * ## Component
 */
const MobileMenu = ({ categoryList, hidden, onClick }: MoblieMenuProps) => {
    const isInfinityClient = ClientName === 'infinity';

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement | null>(null);
    const navClassName: ClassValue = clsx(
        styles['mobile-menu'],
        {
            [styles['mobile-menu--hidden']]: hidden,
            [styles['mobile-menu--infinity']]: isInfinityClient,
        },
    );

    const handleOutsideClick = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node) && !hidden) {
            onClick();
        }
    };

    useEffect(() => {
        // Click event listener when the component mounts
        document.addEventListener('click', handleOutsideClick);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    });

    const handleClick = (event) => {
        event.stopPropagation();
        setIsOpen((prev) => !prev);
    };

    const renderChildrenMenu = (list) => {
        return list.map((item) => (
            <li key={item.id} className={clsx(styles['children-menu__item'], { [styles['children-menu__item--open']]: isOpen })}>
                <a href={item.link} className={styles['children-menu__item-link']}>
                    {item.name}
                    <img src={ChevronDownIcon} alt="" className={styles['children-menu__item-link__icon']} />
                </a>
            </li>
        ));
    };

    return (
        <nav
            ref={menuRef}
            className={navClassName}
        >
            <h2 className="sr-only">Category Navigation</h2>
            <ul className={styles['navlist']}>
                {categoryList.map((category) => (
                    <React.Fragment key={category.name}>
                        <li className={styles['list-item']}>
                            {category.link === '' ? (
                                <span
                                    className={clsx(
                                        styles['list-link'],
                                        styles['list-link--disabled'],
                                    )}
                                    title={category.title}
                                >
                                    {category.name}
                                </span>
                            ) : (
                                <a
                                    type="button"
                                    className={styles['list-link']}
                                    href={category.link}
                                    onClick={onClick}
                                >
                                    {category.name}
                                </a>
                            )}
                            {category.children && (
                                <button type="button" className={clsx(styles['expand-button'], styles['expand-button'])} onClick={handleClick}>
                                    <img src={ChevronDownIcon} alt="" className={clsx(styles['expand-button__icon'], { [styles['expand-button__icon--open']]: isOpen })} />
                                </button>
                            )}
                        </li>
                        {category.children && renderChildrenMenu(category.children)}
                    </React.Fragment>
                ))}

                {isInfinityClient && (
                    <li className={styles['list-item']}>
                        <CtaButton
                            textLabel="Get in Touch"
                            link="mailto:info@infinity-browsers.com"
                            clientName={ClientName}
                            classList={styles['infinity-menu_cta-button']}
                        />
                    </li>
                )}
            </ul>
        </nav>
    );
};

export default MobileMenu;
