/**
 * # Tempest Map
 * @returns React
 */

/**
 * ## Imports
 */
import './TempestMap.scss';
import React, { useState } from 'react';
import TempestMapImage from '../../images/about/map-base-1.png';

/**
 * ## Component output
 * @returns JSX
 */

/**
 * 🚨 Editors beware! This component was copied from the repo `tempest-search`.
 * Any breaking changes need to be kept in sync!
 */
const TempestMap = () => {
    const [mapActive, setMapActive] = useState('all');

    const legendClickHandler = (ref) => {
        setMapActive(ref);
    };

    return (
        <figure className="tempest-map">
            <div className="tempest-map__body">
                <ul className="tempest-map__pins">
                    {/* 🇺🇸 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--us-wa tempest-map__pin--engineering${
                            mapActive === 'engineering' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'engineering' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        USA - Washington
                    </li>

                    {/* 🇺🇸 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--us-nv tempest-map__pin--finance${
                            mapActive === 'finance' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'finance' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        USA - Nevada
                    </li>

                    {/* 🇺🇸 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--us-ny tempest-map__pin--finance${
                            mapActive === 'finance' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'finance' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        USA - New York
                    </li>

                    {/* 🇲🇽 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--mx tempest-map__pin--marketing${
                            mapActive === 'marketing' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'marketing' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        Mexico
                    </li>

                    {/* 🇬🇧 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--uk tempest-map__pin--marketing${
                            mapActive === 'marketing' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'marketing' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        United Kingdom
                    </li>

                    {/* 🇮🇪 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--ie tempest-map__pin--finance${
                            mapActive === 'finance' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'finance' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        Ireland
                    </li>

                    {/* 🇫🇷 / 🇩🇪 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--fr-de tempest-map__pin--engineering${
                            mapActive === 'engineering' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'engineering' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        France / Germany
                    </li>

                    {/* 🇩🇪 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--de tempest-map__pin--product${
                            mapActive === 'product' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'product' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        Germany
                    </li>

                    {/* 🇵🇹 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--pt tempest-map__pin--design${
                            mapActive === 'design' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'design' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        Portugal
                    </li>

                    {/* 🇫🇷 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--re tempest-map__pin--engineering${
                            mapActive === 'engineering' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'engineering' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        La Reunion
                    </li>

                    {/* 🇹🇭 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--th tempest-map__pin--engineering${
                            mapActive === 'engineering' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'engineering' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        Thailand
                    </li>

                    {/* 🇸🇬 */}
                    <li
                        className={`tempest-map__pin tempest-map__pin--sg tempest-map__pin--operations${
                            mapActive === 'operations' ? ' tempest-map__pin--active' : ''
                        }${mapActive !== 'operations' && mapActive !== 'all' ? ' tempest-map__pin--invisible' : ''}`}
                    >
                        Singapore
                    </li>
                </ul>

                <img
                    src={TempestMapImage}
                    alt="Tempest Map"
                    className="tempest-map__image"
                />
            </div>{' '}
            {/* /.tempest-map__body */}
            <figcaption className="tempest-map__legend">
                <ul className="tempest-map__legend-list">
                    <li className="tempest-map__legend-item">
                        <button
                            type="button"
                            className={`tempest-map__button tempest-map__button--all${
                                mapActive === 'all' ? ' tempest-map__button--active' : ''
                            }`}
                            onClick={() => legendClickHandler('all')}
                        >
                            <span>All</span>
                        </button>
                    </li>
                    <li className="tempest-map__legend-item">
                        <button
                            type="button"
                            className={`tempest-map__button tempest-map__button--engineering${
                                mapActive === 'engineering' ? ' tempest-map__button--active' : ''
                            }`}
                            onClick={() => legendClickHandler('engineering')}
                        >
                            <span>Engineering</span>
                        </button>
                    </li>
                    <li className="tempest-map__legend-item">
                        <button
                            type="button"
                            className={`tempest-map__button tempest-map__button--product${
                                mapActive === 'product' ? ' tempest-map__button--active' : ''
                            }`}
                            onClick={() => legendClickHandler('product')}
                        >
                            <span>Product</span>
                        </button>
                    </li>
                    <li className="tempest-map__legend-item">
                        <button
                            type="button"
                            className={`tempest-map__button tempest-map__button--marketing${
                                mapActive === 'marketing' ? ' tempest-map__button--active' : ''
                            }`}
                            onClick={() => legendClickHandler('marketing')}
                        >
                            <span>Marketing</span>
                        </button>
                    </li>
                    <li className="tempest-map__legend-item">
                        <button
                            type="button"
                            className={`tempest-map__button tempest-map__button--design${
                                mapActive === 'design' ? ' tempest-map__button--active' : ''
                            }`}
                            onClick={() => legendClickHandler('design')}
                        >
                            <span>Design</span>
                        </button>
                    </li>
                    <li className="tempest-map__legend-item">
                        <button
                            type="button"
                            className={`tempest-map__button tempest-map__button--finance${
                                mapActive === 'finance' ? ' tempest-map__button--active' : ''
                            }`}
                            onClick={() => legendClickHandler('finance')}
                        >
                            <span>Finance</span>
                        </button>
                    </li>
                    <li className="tempest-map__legend-item">
                        <button
                            type="button"
                            className={`tempest-map__button tempest-map__button--operations${
                                mapActive === 'operations' ? ' tempest-map__button--active' : ''
                            }`}
                            onClick={() => legendClickHandler('operations')}
                        >
                            <span>Operations</span>
                        </button>
                    </li>
                </ul>
            </figcaption>
        </figure>
    );
};

export default TempestMap;
