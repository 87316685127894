/**
 * # Cta Button Text Vault
 */

/**
 * ## Imports
 */
import CtaButton from '../CtaButton/CtaButton';
import appleAppStoreIcon from '../../images/logos/apple-appstore.svg';
import googlePlayStoreIcon from '../../images/logos/google-playstore.svg';

/**
 * ## Component Output
 */
const CtaButtonTextVault = () => {
    const svgLabelApple = {
        src: appleAppStoreIcon,
        altText: 'Download on the Apple App Store',
    };

    const svgLabelGoogle = {
        src: googlePlayStoreIcon,
        altText: 'Download on the Google Play Store',
    };

    return (
        <ul className="button-list">
            <li className="button-list__item">
                <CtaButton
                    link="https://apps.apple.com/gb/app/text-vault-texting-app/id1186010974"
                    svgLabel={svgLabelApple}
                />
            </li>
            <li className="button-list__item">
                <CtaButton
                    link="https://play.google.com/store/apps/details?id=com.appsverse.textvault"
                    svgLabel={svgLabelGoogle}
                />
            </li>
        </ul>
    );
};

/**
 * ## Exports
 */
export default CtaButtonTextVault;