/**
 * # Desktop Menu
 * - The non-mobile visualization of menu items inside the main navigation.
 */
/**
 * ## Imports
 */
import React from 'react';
import clsx from 'clsx';
import CategoryList from 'src/@types/categoryList';
import { useNavigate, useLocation } from 'react-router-dom';
import ChevronDownIcon from 'src/images/infinity/icons/chevron-down.png';
import styles from './Menu.module.scss';

/**
 * ## Types / Interfaces
 */
type MenuProps = {
    categoryList: CategoryList[];
    classList?: string;
    isFooter?: boolean;
}

/**
 * ## Component Output
 */
const Menu: React.FC<MenuProps> = ({ categoryList, classList, isFooter = false }) => {
    const location = useLocation();

    /**
     * ### Handlers
     */
    const handleNavigationAway = () => {
        if (window.location.pathname === '/') {
            localStorage.setItem('scrollPosition', window.pageYOffset.toString());
        }
    };

    const navigate = useNavigate();

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, link: string) => {
        e.preventDefault();

        const path = window.location.pathname;

        // Check if the current path is the homepage and the link is a hash/anchor:
        if (path === '/' && link.startsWith('#')) {
            const section = document.querySelector(link);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
        } else if (link.startsWith('#')) {
            // Redirect to the homepage and let the scroll handler there handle the scrolling
            navigate(`/${link}`);
        } else if (link.startsWith('http')) {
            window.location.href = link;
        } else {
            navigate(link);
        }
    };

    if (categoryList.length === 0) {
        return null;
    }

    const renderChildrenMenu = (list) => (
        <ul className={styles['children-menu']}>
            {list.map((item) => (
                <li key={item.id} className={styles['children-menu__item']}>
                    <a href={item.link} className={styles['children-menu__item-link']}>
                        {item.name}
                        <img src={ChevronDownIcon} alt="" className={styles['children-menu__item-link__icon']} />
                    </a>
                </li>
            ))}
        </ul>
    );

    return (
        <ul className={clsx(styles['menu'], classList)}>
            {categoryList.map((category) => {
                const isNaviLinkActive = (location.pathname === category.link);

                let content;

                if (category.link === '') {
                    content = (
                        <span
                            className={clsx(
                                styles['link'],
                                styles['link--forbidden'],
                            )}
                            title={category.title}
                        >
                            {category.name}
                        </span>
                    );
                } else if (isNaviLinkActive) {
                    content = (
                        <strong
                            className={`${styles['link']} ${styles['link--active']}`}
                        >
                            {category.name}
                        </strong>
                    );
                } else {
                    content = (
                        <a
                            href={category.link}
                            className={styles['link']}
                            onClick={(e) => {
                                handleLinkClick(e, category.link);
                                handleNavigationAway();
                            }}
                        >
                            {category.name}
                        </a>
                    );
                }

                return (
                    <li className={clsx(styles['list-item'], { [styles['list-item__has-children']]: !!category.children })} key={category.name}>
                        {content}
                        {category.children && !isFooter && renderChildrenMenu(category.children)}
                        {category.children && !isFooter && <img src={ChevronDownIcon} alt="" className={styles['list-item__has-children__icon']} />}
                    </li>
                );
            })}
        </ul>
    );
};

export default Menu;