/**
 * # Text Vault Page
 */

/**
 * ## Imports
 */
import PageLayout from 'src/components/PageLayout/PageLayout';
import CookieConsentBanner from 'src/components/CookieConsentBanner/CookieConsentBanner';
import TextVaultPageContent from '../../components/TextVaultPageContent/TextVaultPageContent';
import '../../styles/base.scss';

/**
 * ## Component Output
 */
const TextVaultPage = () => {
    return (
        <PageLayout>
            <CookieConsentBanner />
            <TextVaultPageContent />
        </PageLayout>
    );
};

export default TextVaultPage;
