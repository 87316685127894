/**
 * # Zap Scan Page
 */

/**
 * ## Imports
 */
import PageLayout from 'src/components/PageLayout/PageLayout';
import CookieConsentBanner from 'src/components/CookieConsentBanner/CookieConsentBanner';
import ZapScanPageContent from '../../components/ZapScanPageContent/ZapScanPageContent';
import '../../styles/base.scss';

/**
 * ## Component Output
 */
const ZapScanPage = () => {
    return (
        <PageLayout>
            <CookieConsentBanner />
            <ZapScanPageContent />
        </PageLayout>
    );
};

/**
 * ## Exports
 */
export default ZapScanPage;
