/**
 * # Page Header
 */
/**
 * ## Imports
 */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CategoryList from 'src/@types/categoryList';
import clsx from 'clsx';
import Logo from '../Logo/Logo';
import Menu from '../Menu/Menu';
import ButtonBurger from '../ButtonBurger/ButtonBurger';
import styles from './PageHeader.module.scss';
import CtaButton from '../CtaButton/CtaButton';

/**
 * ## Types / Interfaces
 */
interface IProps {
    categoryList: CategoryList[];
    clientName: string;
    opened: boolean;
    toggleOpened: () => void;
    isMobile: boolean;
}

/**
 * ## Component Output
 */
const PageHeader = ({
    categoryList,
    clientName,
    opened,
    toggleOpened,
    isMobile,
}: IProps) => {
    /**
     * ### Vars
     */
    const isInfinityClient = clientName === 'infinity';

    /**
     * ### Effects
     */
    useEffect(() => {
        const el = document.querySelector('.page-header');

        function handleScroll() {
            if (window.scrollY > 0) {
                el?.classList.add(styles[`page-header--scrolled${isInfinityClient ? '-infinity' : ''}`]);
            } else {
                el?.classList.remove(styles[`page-header--scrolled${isInfinityClient ? '-infinity' : ''}`]);
            }
        }

        window.addEventListener('scroll', handleScroll, true);
        window.addEventListener('resize', handleScroll, true);
    });

    /**
     * ### JSX Output
     */
    return (
        <header className={clsx(
            'page-header',
            styles['page-header'],
            {
                [styles['page-header--infinity']]: isInfinityClient,
            },
        )}
        >
            <div className={styles['page-header__body']}>
                <Link to="/">
                    <Logo clientName={clientName} height={!isMobile ? 76 : 61} />
                </Link>
                {(isMobile && isInfinityClient) && <div className={styles['grow']} />}
                {isInfinityClient ? (
                    <div className={styles['page-header__menu-wrapper']}>
                        {!isMobile && <Menu categoryList={categoryList} classList={isInfinityClient ? styles['infinity-menu'] : ''} />}
                        {
                            !isMobile && isInfinityClient && (
                                <CtaButton
                                    textLabel="Get in Touch"
                                    link="mailto:info@infinity-browsers.com"
                                    clientName={clientName}
                                    classList={styles['infinity-menu_cta-button']}
                                />
                            )
                        }
                    </div>
                ) : !isMobile && <Menu categoryList={categoryList} classList={isInfinityClient ? styles['infinity-menu'] : ''} />}

                {(isMobile) && <ButtonBurger onClick={toggleOpened} opened={opened} isInfinity={isInfinityClient} />}
                {/* {(isMobile && isInfinityClient) && <ButtonOptions onClick={toggleOpened} opened={opened} />} */}
            </div>
        </header>
    );
};

/**
 * ## Exports
 */
export default PageHeader;
