import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToHash = () => {
    const location = useLocation();

    useEffect(() => {
        const scrollToSection = () => {
            if (location?.hash) {
                const section = document?.querySelector(location?.hash);
                if (section) {
                    const yOffset = -100;
                    const yPosition = section.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({ top: yPosition, behavior: 'smooth' });
                }
            }
        };

        const isMobile = /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);

        if (isMobile) {
            setTimeout(scrollToSection, 300);
        } else {
            scrollToSection();
        }
    }, [location]);
};

export default useScrollToHash;
