import MichaelLevit from 'src/images/infinity/peoples/michael-levit.png';
import DannyMiller from 'src/images/infinity/peoples/danny-miller.png';
import IonutVidu from 'src/images/infinity/peoples/ionut-vidu.png';
import AlexanderKomarnitsky from 'src/images/infinity/peoples/alexander-komarnitsky.png';
import DarrenWalsh from 'src/images/infinity/peoples/darren-walsh.png';
import HelenTabb from 'src/images/infinity/peoples/about-portrait-helen-70.png';

export const leadership = [
    {
        avatar: MichaelLevit,
        name: 'Michael Levit',
        role: 'CEO',
    },
    {
        avatar: DannyMiller,
        name: 'Danny Miller',
        role: 'CTO',
    },
    {
        avatar: IonutVidu,
        name: 'Ionut Vidu',
        role: 'GM Browsers',
    },
    {
        avatar: AlexanderKomarnitsky,
        name: 'Alexander Komarnitsky',
        role: 'Head of Browser Tech',
    },
    {
        avatar: DarrenWalsh,
        name: 'Darren Walsh',
        role: 'Head of Browser Product',
    },
    {
        avatar: HelenTabb,
        name: 'Helen Tabb',
        role: 'People',
    },
];