/**
 * # Meteor Router
 */
/**
 * ## Imports
 */
import { CLIENT } from './client/clientConfig';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import CookiePolicyPage from './pages/CookiePolicyPage';
import NotFoundPage from './pages/NotFoundPage';
import PhonerPage from './pages/PhonerPage';
import PrivacyPolicyPage from './pages/PrivacyPolicy';
import TermsAndConditionsPage from './pages/TermsAndConditions';
import TextVaultPage from './pages/TextVaultPage';
import ZapScanPage from './pages/ZapScanPage';

/**
 * ## Routes
 */
export const routesMeteor = (CLIENT.meteor.hasNavigationRoutes) ? [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/about',
        element: <AboutPage />,
    },
    {
        path: '/cookie-policy',
        element: <CookiePolicyPage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
    {
        path: '/phoner',
        element: <PhonerPage />,
    },
    {
        path: '/privacy',
        element: <PrivacyPolicyPage />,
    },
    {
        path: '/terms',
        element: <TermsAndConditionsPage />,
    },
    {
        path: '/text-vault',
        element: <TextVaultPage />,
    },
    {
        path: '/zap-scan',
        element: <ZapScanPage />,
    },
] : [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
];