/**
 * # Not Found
 */
/**
 * ## Imports
 */
import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import styles from './NotFound.module.scss';

/**
 * ## Types / Interfaces
 */

/**
 * ## Component
 */
const NotFound = () => {
    return (
        <section className={clsx(
            styles['not-found'],
            styles['centerwrapper'],
        )}
        >
            <h1 className={styles['headline']}>Error 404 🚨</h1>
            <h2 className={styles['subheadline']}>Page Not Found.</h2>
            <p className={styles['paragraph']}>The page you are looking for does not exist.</p>
            <Link to="/" className={styles['link']}>
                Back to Home
            </Link>
        </section>
    );
};

export default NotFound;