/**
 * # Promo Box List
 */

/**
 * ## Imports
 */
import clsx, { ClassValue } from 'clsx';
import styles from './PromoBoxList.module.scss';

/**
 * ## Types / Interfaces
 */
export type PromoBoxListContent = {
    bulletText: string;
    iconSrc: string;
    iconAltText: string;
};
type PromoBoxListProps = {
    list: PromoBoxListContent[];
    classList?: string;
};

/**
 * ## Component Output
 */
const PromoBoxList: React.FC<PromoBoxListProps> = ({ list, classList }) => {
    const classes: ClassValue = clsx(
        styles['promo-box-list'],
        {
            [classList]: classList,
        },
    );

    return (
        <ul className={classes}>
            {list.map((item) => (
                <li className={styles['promo-box-list__item']}>
                    <img
                        src={item.iconSrc}
                        alt={item.iconAltText}
                        className={styles['promo-box-list__icon']}
                    />
                    <strong className={styles['promo-box-list__label']}>{item.bulletText}</strong>
                </li>
            ))}
        </ul>
    );
};

/**
 * ## Exports
 */
export default PromoBoxList;