/**
 * # Meteor Address
 */
/**
 * ## Address Data
 */
const addressData = {
    website: 'www.meteormobile.com',
    email: 'contact@meteormobile.com',
    company: 'Meteor Mobile LLC',
    address: 'Embarcadero Center',
    floor: '8th Floor',
    city: 'San Francisco',
    state: 'California',
    zip: '94111',
};

/**
 * ## Component Output
 */
const MeteorAddress = () => {
    return (
        <p>{addressData.website}<br />
            <a href={`mailto:${addressData.email}`}>{addressData.email}</a><br />
            {addressData.company}<br />
            {addressData.address}<br />
            {addressData.floor}<br />
            {addressData.city}, {addressData.state} {addressData.zip}
        </p>
    );
};

export { MeteorAddress, addressData };