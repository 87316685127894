/**
 * # Infinity Router
 */
/**
 * ## Imports
 */
import { CLIENT } from './client/clientConfig';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import CustomBrowserPage from './pages/CustomBrowserPage';
import NotFoundPage from './pages/NotFoundPage';
// import ContactPage from './pages/ContactPage';

/**
 * ## Routes
 */
export const routesInfinity = (CLIENT.meteor.hasNavigationRoutes) ? [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '/custom-browser',
        element: <CustomBrowserPage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
    {
        path: '/about',
        element: <AboutPage />,
    },
] : [
    {
        path: '/',
        element: <HomePage />,
    },
    {
        path: '*',
        element: <NotFoundPage />,
    },
];