/**
 * # App
 */

/**
 * ## Imports
 */
import { ClientName } from 'src/client/clientConfig';
import PageLayout from 'src/components/PageLayout/PageLayout';
import CookieConsentBanner from 'src/components/CookieConsentBanner/CookieConsentBanner';
import HeroSectionWithImage from 'src/components/HeroSectionWithImage/HeroSectionWithImage';
import CtaButton from 'src/components/CtaButton/CtaButton';
import CustomBrowserSection from 'src/components/CustomBrowserSection/CustomBrowserSection';
import Testimonials from 'src/components/Testimonials/Testimonials';
import useScrollToHash from 'src/hooks/useScrollToHash';
import HeroSection from '../../components/HeroSection/HeroSection';
import AboutUsSection from '../../components/AboutUsSection/AboutUsSection';
import '../../styles/base.scss';
import styles from './HomePage.module.scss';

/**
 * ## Component
 */
const HomePage = () => {
    /**
     * ### Vars
     */
    const isMeteorClient = ClientName === 'meteor';
    const isInfinityClient = ClientName === 'infinity';

    /**
     * ### Effects
     */
    // useEffect(() => {
    //     const storedScrollPosition = localStorage.getItem('scrollPosition');
    //     if (storedScrollPosition && !window.location.href.includes('#')) {
    //         window.scrollTo(0, parseInt(storedScrollPosition, 10));
    //         localStorage.removeItem('scrollPosition');
    //     }
    // }, []);

    useScrollToHash();

    /**
     * ### JSX Output
     */
    return (
        <PageLayout>
            {isMeteorClient && (
                <>
                    <CookieConsentBanner />
                    <HeroSection />
                </>
            )}
            {isInfinityClient && (
                <>
                    <HeroSectionWithImage clientName={ClientName} />
                    <CustomBrowserSection />
                    <div id="our-mission" className={styles['mission']}>
                        <div className={styles['mission--body']}>
                            <div>
                                <h2 className={styles['mission-headline-quinary']}>Our mission</h2>
                                <h3 className={styles['mission-headline-secondary']}>Your browser,<br /> Your vision.</h3>
                                <CtaButton
                                    textLabel="Get in Touch"
                                    classList={styles['mission--button']}
                                    clientName={ClientName}
                                    link="mailto:info@infinity-browsers.com"
                                />
                            </div>
                            <div>
                                <p className={styles['mission--body-text']}>At Infinity Browsers, we build and manage browser solutions that amplify brand stories, allowing you to connect with more customers.</p>
                                <p className={styles['mission--body-text']}>Supported by a team of world-class engineers, we make browser technology accessible, customizable, and quick-to-market for brands 🚀</p>
                            </div>
                        </div>
                    </div>
                    <Testimonials />
                </>
            )}
            {isMeteorClient && (
                <AboutUsSection />
            )}
        </PageLayout>
    );
};

export default HomePage;
