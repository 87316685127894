import HeartBrowserIcon from 'src/images/infinity/illustrations/heart-browser-icon.png';
import EcosiaLogo from 'src/images/infinity/logos/ecosia.png';
import HeartBrowserLogo from 'src/images/infinity/logos/heart-browser-logo.png';

export default [
    {
        text: <span>“Building a browser is an extraordinary journey, and partnering with Tempest was the best decision we could have made.”</span>,
        author: 'Christian Kroll',
        position: 'CEO Ecosia',
        logo: EcosiaLogo,
        alt: 'Ecosia logo',
        situation: <span>Ecosia is a search engine that dedicates all its profits to climate action. As the driving force behind the planet&rsquo;s largest tree-planting movement, every new user makes a significant impact.</span>,
        challenge: 'Diversify the product offering to create more value for users, thereby increasing engagement and fostering long-term retention.',
        solution: 'The greenest browser on Earth 🌏. The only one that empowers people to plant trees and support climate projects simply by browsing, searching, and shopping.',
    },
    {
        text: <span>“Bringing our idea to life demanded a unique product offering, and Tempest&rsquo;s expertise and dedication have proven invaluable in building the Heart browser.”</span>,
        author: 'Tom Bloemendaal',
        position: 'Lunar Paladins Software',
        logo: HeartBrowserLogo,
        alt: 'Heart Browser logo',
        situation: <span>Heart was inspired by Peter Singer&rsquo;s book and organization named &ldquo;The Life You Can Save&rdquo;, where contributors choose charities where each dollar can be maximized to save lives and improve the well-being of others.</span>,
        challenge: 'Traditionally a lot of organisations rely on monetary donations, Heart wanted a way for users to donate in a way that feels more natural.',
        solution: <span>Browsing with Heart <img alt="Heart Browser icon" src={HeartBrowserIcon} /> feels good and makes a real difference. Heart donates all of its profits to impactful charity partners, so browse generously!</span>,
    },
];