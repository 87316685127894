/**
 * # Infinity About Page Content
 */

/**
 * ## Imports
 */
import clsx from 'clsx';
import { leadership } from 'src/@constants/employees';
import TempestMap from '../TempestMap/TempestMap';
import styles from './InfinityAboutPageContent.module.scss';
import CtaTeaser from '../CtaTeaser/CtaTeaser';

/**
 * ## Component
 */
const AboutPageContent = () => {
    return (
        <div className={styles['about-us']}>
            <CtaTeaser classList={styles['about-teaser-wrapper']} />
            <div className={styles['leadership']}>
                <div className={clsx('centerwrapper')}>
                    <p className={clsx('headline-quinary', styles['quinary'])}>Leadership</p>
                    <div className={styles['leaders']}>
                        {leadership.map((leader) => (
                            <div key={leader.name} className={styles['leader']}>
                                <img alt="Michael Levit" src={leader.avatar} className={styles['leader-avatar']} />
                                <div className={styles['leader-info']}>
                                    <p className={styles['leader-name']}>{leader.name}</p>
                                    <p className={styles['leader-role']}>{leader.role}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles['team']}>
                        <p className={styles['team-quinary']}>Team</p>
                        <h2 className={styles['team-headline']}>Our team around the globe</h2>
                    </div>
                </div>
            </div>
            <div className={styles['map']}>
                <TempestMap />
            </div>
            <div className={styles['boxed-content']}>
                <p className={styles['boxed-content-text']}>At Infinity browsers, we celebrate uniqueness & authenticity. We bring passion and lead with empathy. We celebrate our backgrounds and personal stories. What makes us different makes us great.</p>
            </div>
        </div>
    );
};

/**
 * ## Exports
 */

export default AboutPageContent;