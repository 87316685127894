import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ScrollToTop } from './helpers/scrollToTop';
import { ClientName } from './client/clientConfig';
import { setFavicon } from './client/setFavicon';
import { MobileMenuProvider } from './context/MobileMenuContext';
import { routesMeteor } from './routesMeteor';
import { routesInfinity } from './routesInfinity';
import reportWebVitals from './reportWebVitals';

/**
 * ## Setting up the document's title and favicons.
 */
setFavicon({ client: ClientName });

const isInfinityClient = ClientName === 'infinity';
const routes = isInfinityClient ? routesInfinity : routesMeteor;
const root = ReactDOM.createRoot(document.getElementById('root'));

if (isInfinityClient) {
    document.getElementById('root').classList.add('infinity');
}

root.render(
    <Router>
        <MobileMenuProvider>
            <React.StrictMode>
                <ScrollToTop />
                <Routes>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            element={route.element}
                        />
                    ))}
                </Routes>
            </React.StrictMode>
        </MobileMenuProvider>
    </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
