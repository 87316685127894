/**
 * # About Us Section
 */
/**
 * ## Imports
 */
import CtaButtonPhoner from '../CtaButtonPhoner/CtaButtonPhoner';
import CtaButtonTextVault from '../CtaButtonTextVault/CtaButtonTextVault';
import CtaButtonZapScan from '../CtaButtonZapScan/CtaButtonZapScan';
import phonerImageSourceMobile from '../../images/phoner_mobile.png';
import phonerImageSourceLarge from '../../images/phoner_large.png';
import textVaultImageSourceMobile from '../../images/text-vault_mobile.png';
import textVaultImageSourceLarge from '../../images/text-vault_large.png';
import scannerImageSourceMobile from '../../images/scanner_mobile.png';
import scannerImageSourceLarge from '../../images/scanner_large.png';
import styles from './AboutUsSection.module.scss';

/**
 * ## Component Output
 */
const AboutUsSection = () => {
    return (
        <section className={styles['about-us']}>
            <div className={styles['about-us__ellipse']}>
                <div className="ladyfingers" />
                <div className={styles['about-us__body']}>
                    <h2 className="headline-quinary">About Us</h2>
                    <h3 className={styles['about-us__headline-inconsistent']}>What We Do</h3>
                    <p className="paragraph-lean">At Meteor Mobile, we&apos;re building a universe of top-tier mobile apps designed to enhance communication, security and productivity.
                    </p>

                    <p className="paragraph-lean">Led by a team of world-class engineers, our flight plan over the next year includes launching a suite of utility apps that will dramatically improve your daily life  🚀</p>
                </div>

                <section className={styles['phoner']} id="phoner">
                    <div className={styles['phoner__ladyfingers']} />
                    <div className="deco-knight-rider" />
                    <div className={styles['phoner__body']}>
                        <h3 className={styles['phoner__headline-inconsistent']}>Our Apps</h3>

                        <div className={styles['phoner__inner']}>
                            <div className={styles['phoner__text-wrapper']} id="phoner">
                                <h4 className={styles['headline-tertiary']}>Phoner</h4>
                                <p className="paragraph-lean">Phoner makes it easy to add unlimited temporary phone numbers to protect your privacy and keep your information secure, right from any mobile device.</p>
                                <CtaButtonPhoner />
                            </div>
                            <div className={styles['phoner__illustration-wrapper']}>
                                <img
                                    alt="Phoner App Visualization"
                                    className={styles['phoner__image']}
                                    src={phonerImageSourceMobile}
                                    srcSet={`${phonerImageSourceMobile} 786w, ${phonerImageSourceLarge} 1042w`}
                                    sizes="(max-width: 768px) 786px, 1042px"
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>{/* /.about-us__ellipse */}
            <section className={styles['text-vault']} id="text-vault">
                <div className={styles['text-vault__ladyfingers']} />
                <div className={styles['text-vault__body']}>
                    <div className={styles['text-vault__inner']}>
                        <div className={styles['text-vault__text-wrapper']}>
                            <h4 className={styles['headline-tertiary']}>Text Vault</h4>
                            <p className="paragraph-lean">Text Vault allows anyone to text and receive messages anonymously through one secure mobile app.</p>
                            <CtaButtonTextVault />
                        </div>
                        <div className={styles['text-vault__illustration-wrapper']}>
                            <img
                                alt="Text Vault App Visualization"
                                className={styles['text-vault__image']}
                                src={textVaultImageSourceMobile}
                                srcSet={`${textVaultImageSourceMobile} 786w, ${textVaultImageSourceLarge} 1042w`}
                                sizes="(max-width: 768px) 786px, 1042px"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className={styles['scanner']} id="scanner">
                <div className="deco-knight-rider" />
                <div className={styles['scanner__ladyfingers']} />
                <div className={styles['scanner__body']}>
                    <div className={styles['scanner__inner']}>
                        <div className={styles['scanner__text-wrapper']}>
                            <h4 className={styles['headline-tertiary']}>Zap Scan</h4>
                            <p className="paragraph-lean">Our Scanner App instantly reads any document or image and saves it as a high-quality <abbr title="Portable Document Format.">PDF</abbr> that can be easily shared.</p>
                            <CtaButtonZapScan />
                        </div>
                        <div className={styles['scanner__illustration-wrapper']}>
                            <img
                                alt="Phoner App Visualization"
                                className={styles['scanner__image']}
                                src={scannerImageSourceMobile}
                                srcSet={`${scannerImageSourceMobile} 786w, ${scannerImageSourceLarge} 1042w`}
                                sizes="(max-width: 768px) 786px, 1042px"
                            />
                        </div>
                    </div>
                </div>
            </section>

        </section>
    );
};

export default AboutUsSection;