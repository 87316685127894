/**
 * # Cookie Policy Page
 */
/**
 * ## Imports
 */
import PageLayout from 'src/components/PageLayout/PageLayout';
// import CookieConsentBanner from 'src/components/CookieConsentBanner/CookieConsentBanner';
import CopyText from 'src/components/CopyText';
import { ClientName } from 'src/client/clientConfig';
import GetCookieContent from './utils/getCookieContent';

/**
 * ## Component Output
 */
const CookiePolicyPage = () => {
    return (
        <PageLayout>
            {/* <CookieConsentBanner /> */}
            <CopyText clientName={ClientName}>{GetCookieContent()}</CopyText>
        </PageLayout>
    );
};

export default CookiePolicyPage;
