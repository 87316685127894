/**
 * # Logo
 */

/**
 * ## Imports
 */
import React from 'react';
import clsx from 'clsx';
import { ReactComponent as LogoIconMeteor } from '../../images/logos/logo-meteor.svg';
import { ReactComponent as LogoIconInfinity } from '../../images/infinity/logos/logo-infinity.svg';
import styles from './Logo.module.scss';

/**
 * ## Types / Interfaces
 */
type LogoProps = {
    clientName: string;
    height?: number;
}

/**
 * ## Component output
 */
const Logo: React.FC<LogoProps> = ({ clientName, height }) => {
    /**
     * ### Variables
     */
    const isInfinityClient = clientName === 'infinity';

    /**
     * ### JSX Output
     */
    return (
        <div
            className={clsx(
                styles['logo-wrapper'],
                styles[`logo-wrapper--${height}`],
                {
                    [styles['logo-wrapper--desktop']]: height === 76,
                    [styles['logo-wrapper--mobile']]: height === 61,
                    [styles['logo-wrapper--infinity']]: isInfinityClient,
                },
            )}
        >
            {isInfinityClient ? <LogoIconInfinity /> : <LogoIconMeteor />}
        </div>
    );
};

/**
 * ## Exports
 */
export default Logo;
