/**
 * # Scroll to Top - automatically scrolls to the top when `<Link>` is clicked.
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = () => {
    const location = useLocation();

    // useEffect(() => {
    //     if (!hash) {
    //         window.scrollTo(0, 0);
    //     }
    // }, [pathname, hash]);

    useEffect(() => {
        const prevLocation = JSON.parse(localStorage.getItem('prev-location') || '{}');
        localStorage.setItem('prev-location', JSON.stringify(location));

        if ((prevLocation?.hash && prevLocation?.pathname === '/') || location?.pathname === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }

        if (!location?.hash) {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return null;
};