/**
 * # Zap Scan Page Content
 */

/**
 * ## Imports
 */
import IllustrationRightOverlap from '../IllustrationRightOverlap/IllustrationRightOverlap';
import CtaButtonZapScan from '../CtaButtonZapScan/CtaButtonZapScan';
import PromoBox from '../PromoBox/Promobox';
import styles from './ZapScanPageContent.module.scss';

import zapScanIllustrationSrc from '../../images/illustrations/zap-scan-illustration.png';
import zapScanIconSpeachBubble from '../../images/icons/speach_bubble.svg';
import zapScanText from '../../images/icons/text.svg';
import zapScanSign from '../../images/icons/sign.svg';
import zapScanPrint from '../../images/icons/print.svg';
import zapScanShare from '../../images/icons/share.svg';

/**
 * ## Component
 */
const ZapScanPageContent = () => {
    return (
        <article className={styles['zap-scan']}>
            <div className={`${styles['ladyfingers']}`} />
            <div className={styles['zap-scan__intro']}>
                <IllustrationRightOverlap
                    headline="Zap Scan"
                    subHeadline="Copy & share documents instantly"
                    paragraph="With Zap Scan, you can easily extract text from any document, read QR codes or scan images right to your mobile phone. Organize, sign, share and print all with our mobile app."
                    illustrationSrc={zapScanIllustrationSrc}
                    imageClassName="illustration-zap-scan"
                />
                <div className="illustration-overlap-spacer">
                    <PromoBox
                        headline={
                            <span className="headline-sober">Why customers<br /> love Zap Scan</span>
                        }
                        copytext=""
                        buttonMarkup={(
                            <CtaButtonZapScan />
                        )}
                        list={[
                            {
                                bulletText: 'Scan, crop & filter documents',
                                iconSrc: zapScanIconSpeachBubble,
                                iconAltText: 'Speech Bubble Icon',
                            },
                            {
                                bulletText: 'Extract text from any image',
                                iconSrc: zapScanText,
                                iconAltText: 'Text Icon',
                            },
                            {
                                bulletText: 'Review & sign digitally',
                                iconSrc: zapScanSign,
                                iconAltText: 'Sign Icon',
                            },
                            {
                                bulletText: 'Print wirelessly',
                                iconSrc: zapScanPrint,
                                iconAltText: 'Print Icon',
                            },
                            {
                                bulletText: 'Manage & share scans',
                                iconSrc: zapScanShare,
                                iconAltText: 'Share Icon',
                            },
                        ]}
                    />
                </div>
            </div>
        </article>
    );
};

/**
 * ## Exports
 */
export default ZapScanPageContent;