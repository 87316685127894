/**
 * # Phoner Page Content
 */

/**
 * ## Imports
 */
import IllustrationRightOverlap from '../IllustrationRightOverlap/IllustrationRightOverlap';
import CtaButtonPhoner from '../CtaButtonPhoner/CtaButtonPhoner';
import PromoBox from '../PromoBox/Promobox';
import styles from './PhonerPageContent.module.scss';
import phonerIllustrationSrc from '../../images/illustrations/phoner_illustration.png';
import phonerIconPhone from '../../images/icons/phone.svg';
import phonerIconMap from '../../images/icons/map.svg';
import phonerIconStar from '../../images/icons/star.svg';
import phonerIconClipboard from '../../images/icons/clipboard.svg';
import phonerIconSmartphone from '../../images/icons/smartphone.svg';
import phonerIconRoute from '../../images/icons/route.svg';
import phonerIconBullets from '../../images/icons/bullets.svg';
import phonerIconSpeachBubble from '../../images/icons/speach_bubble.svg';
import phonerIconScreen from '../../images/icons/screen.svg';

/**
 * ## Component
 */
const PhonerPageContent = () => {
    return (
        <article className={styles['phoner']}>
            <div className={`${styles['ladyfingers']}`} />
            <div className={styles['phoner__intro']}>
                <IllustrationRightOverlap
                    headline="Phoner"
                    subHeadline="Text & call with a secure 2nd number"
                    paragraph="Phoner makes it easy to add unlimited temporary numbers to protect your privacy and keep your info secure, right from any mobile device of your desktop."
                    illustrationSrc={phonerIllustrationSrc}
                />
                <div className="illustration-overlap-spacer">
                    <PromoBox
                        headline={
                            <><span className="headline-sober">Phoner</span> for Everybody</>
                        }
                        copytext="Instantly add a second phone number just for shopping, dating online or signing up for a promo from your favorite brand."
                        buttonMarkup={(
                            <CtaButtonPhoner textLabel="Try Phoner Free" classList="cta-button" />
                        )}
                        list={[
                            {
                                bulletText: 'Save time and money',
                                iconSrc: phonerIconPhone,
                                iconAltText: 'Phone Icon',
                            },
                            {
                                bulletText: 'Avoid contracts or commitments',
                                iconSrc: phonerIconClipboard,
                                iconAltText: 'Clipboard Icon',
                            },
                            {
                                bulletText: 'Choose any local area code',
                                iconSrc: phonerIconMap,
                                iconAltText: 'Map Icon',
                            },
                            {
                                bulletText: 'Use your existing mobile device',
                                iconSrc: phonerIconSmartphone,
                                iconAltText: 'Smartphone Icon',
                            },
                            {
                                bulletText: 'Try risk-free for 3 days',
                                iconSrc: phonerIconStar,
                                iconAltText: 'Star Icon',
                            },
                        ]}
                    />
                    <div className="deco-knight-rider" />
                    <PromoBox
                        headline={
                            <><span className="headline-sober">Phoner</span> for Businesses</>
                        }
                        copytext="Grow revenue and scale faster with a dedicated phone line for startups, logistics and transportation, education, food delivery and more."
                        buttonMarkup={(
                            <CtaButtonPhoner textLabel="Try Phoner Free" />
                        )}
                        list={[
                            {
                                bulletText: 'Route calls to your team',
                                iconSrc: phonerIconRoute,
                                iconAltText: 'Route Icon',
                            },
                            {
                                bulletText: 'Choose from thousands of numbers',
                                iconSrc: phonerIconBullets,
                                iconAltText: 'Bullets Icon',
                            },
                            {
                                bulletText: 'Text & call without limits',
                                iconSrc: phonerIconSpeachBubble,
                                iconAltText: 'Speach Bubble Icon',
                            },
                            {
                                bulletText: 'Access on all of your devices, including a desktop version',
                                iconSrc: phonerIconScreen,
                                iconAltText: 'Screen Icon',
                            },
                            {
                                bulletText: 'Try risk-free for 3 days ',
                                iconSrc: phonerIconStar,
                                iconAltText: 'Star Icon',
                            },
                        ]}
                    />
                </div>
            </div>
        </article>
    );
};

/**
 * ## Exports
 */

export default PhonerPageContent;