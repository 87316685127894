/**
 * # Categories
 * - Navigation items etc.
 */

/**
 * ## Exports
 */
export const CATEGORIESMETEOR = [
    {
        name: 'About Us',
        id: 4,
        link: '/about',
        title: '',
    },
    {
        name: 'Phoner',
        id: 1,
        link: '/phoner',
        title: '',
    },
    {
        name: 'Text Vault',
        id: 2,
        link: '/text-vault',
        title: '',
    },
    {
        name: 'Zap Scan',
        id: 3,
        link: '/zap-scan',
        title: '',
    },
];

export const CATEGORIESINFINITY = [
    {
        name: 'Our Mission',
        id: 1,
        footerOrder: 2,
        link: '/#our-mission',
        title: '',
    },
    {
        name: 'Custom Browser',
        id: 2,
        footerOrder: 4,
        link: '/custom-browser',
        title: '',
    },
    {
        name: 'Case studies',
        id: 3,
        footerOrder: 3,
        link: '/#case-studies',
        title: '',
    },
    {
        name: 'About us',
        id: 4,
        footerOrder: 1,
        link: '/about',
        title: '',
    },
];
