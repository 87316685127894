/**
 * # Promo Box
 */

/**
 * ## Imports
 */
import styles from './Promobox.module.scss';
import PromoBoxList, { PromoBoxListContent } from './utils/PromoBoxList/PromoBoxList';

/**
 * ## Types / Interfaces
 */
type PromoBoxProps = {
    headline: React.ReactNode;
    copytext?: string;
    buttonMarkup?: React.ReactNode;
    list?: PromoBoxListContent[];
};

/**
 * ## Component Output
 */
const PromoBox: React.FC<PromoBoxProps> = ({
    headline,
    copytext,
    buttonMarkup,
    list,
}) => {
    return (
        <section className={styles['promo-box']}>
            <div className={styles['promo-box__text']}>
                <h3 className="headline-quaternary">{headline}</h3>
                {copytext && <p className="paragraph-lean">{copytext}</p>}
            </div>
            <div className={styles['promo-box__cta']}>
                {buttonMarkup && buttonMarkup}
            </div>
            <div className={styles['promo-box__usp']}>
                {list && <PromoBoxList list={list} classList="promo-items" />}
            </div>
        </section>
    );
};

/**
 * ## Exports
 */
export default PromoBox;