/**
 * # Hero Section
 * - Introductionary teaser on top of the page.
 */

/**
 * ## Imports
 */
import styles from './HeroSection.module.scss';

/**
 * ## Component Output
 */
const HeroSection = () => {
    return (
        <>
            {/* Note: this is just a `<div>` tag and not a `<section>`/`<article>` tag
              * on purpose to avoid an `unnamed <body>`. */}
            <div className={styles['wrapper']}>
                <div className={styles['starfield']}>
                    <div className={styles['ladyfingers']} />
                    <div className="container-hero">
                        <h1 className={styles['headline-block']}>Meteor Mobile</h1>
                        <h2 className={styles['headline-primary']}>Mobile Apps from the Future</h2>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeroSection;