/**
 * # DecoBoxText
 */

/**
 * ## Imports
 */
import styles from './DecoBoxText.module.scss';

/**
 * ## Component Output
 */
const DecoBoxText = () => {
    return (
        <section className={styles['deco-box-text']}>
            <h3 className={styles['deco-box-text__headline-inconsistent']}><span className="headline-sober">Our</span> History</h3>
            <p className="paragraph-lean">Founded in 2023, Meteor Mobile was launched by Tempest after establishing a mobile presence through a series of strategic acquisitions. Meteor Mobile is focused on creating multiple engineering teams focused on cross platform development for a suite of mobile products.</p>
        </section>
    );
};

/**
 * ## Exports
 */
export default DecoBoxText;