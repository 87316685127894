/**
 * # Illustration Right Overlap
 */

/**
 * ## Imports
 */
import clsx from 'clsx';
import styles from './IllustrationRightOverlap.module.scss';

/**
 * ## Types / Interfaces
 */
type IllustrationRightOverlapProps = {
    headline: string;
    subHeadline: string;
    paragraph: string;
    illustrationSrc: string;
    imageClassName?: string;
}

/**
 * ## Component Output
 */
const IllustrationRightOverlap: React.FC<IllustrationRightOverlapProps> = ({
    headline,
    subHeadline,
    paragraph,
    illustrationSrc,
    imageClassName,
}) => {
    return (
        <div className={styles['setup']}>
            <div className={styles['setup__text']}>
                <h2 className="headline-quinary">{headline}</h2>
                <h3 className={styles['setup__headline-inconsistent']}><span className="headline-sober">{subHeadline}</span></h3>
                <p className="paragraph-lean">{paragraph}</p>
            </div>
            <figure className={styles['setup__image-wrapper']}>
                <img
                    src={illustrationSrc}
                    alt="Application Screenshot"
                    className={clsx(
                        styles['setup__image'],
                        {
                            [imageClassName]: imageClassName,
                        },
                    )}
                />
            </figure>
        </div>
    );
};

/**
 * ## Export
 */
export default IllustrationRightOverlap;