/**
 * # Custom Browser Page
 */

/**
 * ## Imports
 */
import { useEffect, useState } from 'react';
import PageLayout from 'src/components/PageLayout/PageLayout';
import '../../styles/base.scss';
import CtaTeaser from 'src/components/CtaTeaser/CtaTeaser';
import clsx from 'clsx';
import CustomFeatures from 'src/components/CustomFeatures/CustomFeatures';
import ScheduleAMeeting from 'src/components/ScheduleAMeeting/ScheduleAMeeting';
import SonicDefaultImage from 'src/images/infinity/illustrations/sonic-default.png';
import SonicThemedImage from 'src/images/infinity/illustrations/sonic-themed.png';
import SliderButtonImage from 'src/images/infinity/illustrations/dragger-icon.png';
import styles from './CustomBrowserPage.module.scss';

/**
 * ## Component Output
 */
const CustomBrowserPage = () => {
    const [sliderValue, setSliderValue] = useState(20);

    const handleSliderChange = (event) => {
        setSliderValue(event.target.value);
        const container = document.getElementById('slider-container');
        container.style.setProperty('--position', `${event?.target?.value}%`);
    };

    useEffect(() => {
        const handleScroll = () => {
            const sliderContainer = document.getElementById('slider-container');
            const scrollY = window.scrollY;
            const sliderHeight = sliderContainer.offsetHeight;
            const scrollPosition = Math.min(Math.max(scrollY, 0), sliderHeight);
            const newSliderValue = 20 + (75 * scrollPosition) / sliderHeight;
            setSliderValue(newSliderValue);
            sliderContainer.style.setProperty('--position', `${newSliderValue}%`);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <PageLayout>
            <CtaTeaser />
            <div className={styles['sonic-theme-slider']}>
                <div id="slider-container" className={styles['slider-container']}>
                    <div className={styles['image-container']}>
                        <img className={clsx(styles['image-left'], styles['slider-image'])} src={SonicThemedImage} alt="Dark theme" />
                        <img className={clsx(styles['image-right'], styles['slider-image'])} src={SonicDefaultImage} alt="Light theme" />
                    </div>
                    <input
                        type="range"
                        min="0"
                        max="100"
                        step={0.5}
                        value={sliderValue}
                        aria-label="Percentage of left image shown"
                        className={styles['slider']}
                        id="slider"
                        onChange={handleSliderChange}
                    />
                    <div className={styles['slider-line']} aria-hidden="true" />
                    <div className={styles['slider-button']} aria-hidden="true"><img className={styles['slider-button-img']} alt="Slider button icon" src={SliderButtonImage} /></div>
                </div>
            </div>
            <div className={styles['features-wrap']}>
                <div className={clsx('centerwrapper', styles['centerwrapper'])}>
                    <div id="case-studies">
                        <h2 className="headline-quinary">Features</h2>
                        <h3 className={styles['headline-main']}>Key Range of Customizable Features</h3>
                        <p className={styles['paragraph']}>Allow customers to take control over their browsing experience with a range of customization options:</p>
                    </div>
                    <CustomFeatures />
                </div>
            </div>
            <ScheduleAMeeting />
        </PageLayout>
    );
};

export default CustomBrowserPage;
