/**
 * # Cookie Consent Helper 🍪
 */
const COOKIE_NAME = 'cookieAcceptance';

export const hasUserAcceptedCookies = (): boolean => {
    return document.cookie.split('; ').some((cookie) => cookie.includes(`${COOKIE_NAME}=accepted`));
};

export const setUserAcceptedCookies = (): void => {
    const expiryDate = new Date();
    // Set expiration to 1 year from now
    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

    const expiryString = `expires=${expiryDate.toUTCString()}`;
    document.cookie = `${COOKIE_NAME}=accepted; ${expiryString}; path=/`;
};

export const removeUserCookieAcceptance = (): void => {
    // By setting an expired date in the past, the browser removes the cookie, effectively revoking the user's acceptance.
    document.cookie = `${COOKIE_NAME}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};