/**
 * # Page Footer
 */
/**
 * ## Imports
 */
import { Link } from 'react-router-dom';
import CategoryList from 'src/@types/categoryList';
import clsx from 'clsx';
import { ClientName } from 'src/client/clientConfig';
import { useMemo } from 'react';
import Menu from '../Menu/Menu';
import { ReactComponent as LogoIcon } from '../../images/logos/logo-meteor-white.svg';
import { ReactComponent as LogoIconInfinity } from '../../images/infinity/logos/logo-infinity.svg';
import styles from './PageFooter.module.scss';
import CtaButton from '../CtaButton/CtaButton';

/**
 * ## Types / Interfaces
 */
interface IProps {
    categoryList: CategoryList[];
    clientName?: string;
}

/**
 * ## Component Output
 */
const PageFooter = ({ categoryList, clientName }: IProps) => {
    /**
     * ### Variables
     */
    const isMeteorClient = clientName === 'meteor';
    const isInfinityClient = clientName === 'infinity';

    const footerNav = useMemo(() => {
        const categories = [...categoryList];
        return categories.sort((a, b) => a.footerOrder - b.footerOrder);
    }, [categoryList]);

    /**
     * ### JSX Output
     */
    return (
        <footer className={clsx(styles['page-footer'], {
            [styles['infinity-footer']]: isInfinityClient,
        })}
        >
            {isMeteorClient && <div className={styles['page-footer__ladyfingers-2']} />}
            <div className={styles['logo']}>
                <Link to="/">
                    {isInfinityClient
                        ? <LogoIconInfinity className={styles['logo-infinity']} />
                        : <LogoIcon />}
                </Link>
            </div>
            {
                isInfinityClient && (
                    <div className={styles['footer-teaser']}>
                        <p className={styles['footer-teaser-quinary']}>Contact</p>
                        <h2 className={styles['footer-teaser-headline']}>Get started today</h2>
                        <CtaButton
                            clientName={ClientName}
                            textLabel="Get in Touch"
                            classList={styles['footer-teaser-button']}
                            link="mailto:info@infinity-browsers.com"
                        />
                    </div>
                )
            }
            <Menu
                categoryList={footerNav}
                classList={clsx(
                    'menu--footer',
                    {
                        'menu--infinity-footer': isInfinityClient,
                    },
                )}
                isFooter
            />
            <nav>
                <h3 className="sr-only">Legal Information</h3>
                {isMeteorClient && (
                    <ul className={styles['legal-nav']}>
                        <li className={styles['legal-nav__item']}>
                            <Link to="/terms" className={styles['legal-nav__link']}>
                                Terms & Conditions
                            </Link>
                        </li>
                        <li className={styles['legal-nav__item']}>
                            <Link to="/privacy" className={styles['legal-nav__link']}>
                                Privacy Policy
                            </Link>
                        </li>
                        <li className={styles['legal-nav__item']}>
                            <Link to="/cookie-policy" className={styles['legal-nav__link']}>
                                Cookie Policy
                            </Link>
                        </li>
                    </ul>
                )}
            </nav>
            {isInfinityClient && (
                <div className={styles['legal-links']}>
                    <ul>
                        <li className={styles['legal-links--item']}>
                            <a href="https://www.tempest.com/privacy-policy" target="_blank" rel="noopener noreferrer" className={styles['legal-links--item-link']}>Privacy Policy</a>
                        </li>
                        <li className={styles['legal-links--item']}>
                            <a href="https://www.tempest.com/terms-of-service" target="_blank" rel="noopener noreferrer" className={styles['legal-links--item-link']}>Terms of Service</a>
                        </li>
                    </ul>
                    <ul>
                        <li className={clsx(styles['legal-links--item'], styles['social-media'])}>
                            <a href="https://www.linkedin.com/company/tempest-privacy/" target="_blank" rel="noopener noreferrer" className={styles['legal-links--item-link']}>
                                <i className="fa-brands fa-linkedin-in fa-l" />
                            </a>
                            <a href="https://www.facebook.com/tempestprivacy" target="_blank" rel="noopener noreferrer" className={styles['legal-links--item-link']}>
                                <i className="fa-brands fa-facebook-f fa-l" />
                            </a>
                            <a href="https://twitter.com/tempesttechltd" target="_blank" rel="noopener noreferrer" className={styles['legal-links--item-link']}>
                                <i className="fa-brands fa-x-twitter fa-l" />
                            </a>
                        </li>
                        <li className={styles['legal-links--item']}>
                            <small className={styles['imprint']}>© 2024 Tempest</small>
                        </li>
                    </ul>
                </div>
            )}
            {isMeteorClient && <div className={styles['page-footer__ladyfingers']} />}
        </footer>
    );
};

export default PageFooter;