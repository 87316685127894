/**
 * # CTA Teaser
 */

/**
 * ## Imports
 */
import ctaTeaserSmallImageSrcInfinity from 'src/images/infinity/illustrations/sonic-customization-features-small.png';
import ctaTeaserBigImageSrcInfinity from 'src/images/infinity/illustrations/sonic-customization-features-big.png';
import customExtensionTeaserBig from 'src/images/infinity/illustrations/custom-extension-teaser-big.png';
import customExtensionTeaserSmall from 'src/images/infinity/illustrations/custom-extension-teaser-small.png';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import styles from './CtaTeaser.module.scss';
import CtaTeaserIntro from '../CtaTeaserIntro/CtaTeaserIntro';

type CtaTeaserProps = {
    classList?: string;
    hasButton?: boolean;
}

/**
 * ## Component Output
 */
const CtaTeaser: React.FC<CtaTeaserProps> = ({ classList, hasButton = true }) => {
    const location = useLocation();
    const isCustomBrowserPage = location.pathname.includes('/custom-browser');
    const isAboutPage = location.pathname.includes('/about');
    /**
     * ### Client Split
     */
    const ctaTeaserImageBig = isCustomBrowserPage ? ctaTeaserBigImageSrcInfinity : customExtensionTeaserBig;
    const ctaTeaserImageSmall = isCustomBrowserPage ? ctaTeaserSmallImageSrcInfinity : customExtensionTeaserSmall;

    /**
     * ### CSS Classes
     */
    const ctaTeaserClassName = `${styles['cta-teaser-wrapper']} ${styles['cta-teaser-infinity']} ${classList}`;

    /**
     * ### Text contents
     * - Todo: create a new JSX file, let's say CtaTeaserContent.jsx, to hold the content.
     */
    /**
     * #### Headline
     */
    let headlineMain = '';
    if (isCustomBrowserPage) {
        headlineMain = 'Your browser, Your vision.';
    } else if (isAboutPage) {
        headlineMain = 'Hi. We’re Infinity Browsers. Nice to meet you.';
    }

    let headlineQuinary = '';
    if (isCustomBrowserPage) {
        headlineQuinary = 'Custom Browser';
    } else if (isAboutPage) {
        headlineQuinary = 'About us';
    }

    /**
     * #### Copy Text
     */
    let copyText;
    if (isCustomBrowserPage) {
        copyText = (
            <>
                <p className={clsx(styles['intro-paragraph'], 'intro-text')}>Introducing our Custom Browser Solution - the world`s first fully customizable web browser. We support you end-to-end to build a browser with your branding that brings your customers speed, security and ultimate personalization and productivity.</p>
                <p className={clsx(styles['intro-paragraph'], 'intro-text')}>Building your own browser opens up doors to monetization opportunities, allows you to reach and engage with customers in an organic way.</p>
            </>
        );
    } else if (isAboutPage) {
        copyText = <p className={clsx(styles['intro-paragraph'], 'intro-text', { [styles['intro-paragraph-about']]: isAboutPage })}>We’re an independent company on a mission to help you create your browser experience. We’re the company that makes browser technology accessible, customizable, and quick-to-market for brands everywhere</p>;
    }

    /**
     * #### Button Label
     */
    const buttonLabel = 'Get in Touch';

    return (
        <section className={ctaTeaserClassName}>
            <div className={`${styles['cta-teaser']} centerwrapper`}>
                <CtaTeaserIntro
                    headlineLabel={headlineMain}
                    headlineQuinary={headlineQuinary}
                    copyText={copyText}
                    hasButton={hasButton}
                    buttonLabel={buttonLabel}
                />

                {false && (
                    <picture className={styles['cta-teaser__visual']}>
                        <source
                            media="(max-width: 759px)"
                            srcSet={ctaTeaserImageSmall}
                            className={styles['cta-teaser__visual-source']}
                        />
                        <source
                            media="(min-width: 760px)"
                            srcSet={ctaTeaserImageBig}
                            className={styles['cta-teaser__visual-source']}
                        />
                        <img
                            // eslint-disable-next-line
                            alt={`This shows two devices, a desktop monitor and a smartphone, displaying the interface of a web browser named \"Sonic.\" Both screens showcase the browser's start page. On the desktop monitor, the start page includes a search bar with a placeholder text that reads \"Search Tempest or type a URL.\" Below the search bar, there are shortcut icons to various popular websites such as YouTube, Netflix, Booking, Reddit, Amazon, Dribbble, Wikipedia, and Zoom. There's also an option to \"Add shortcut.\" The browser tabs and address bar are visible at the top of the window. On the smartphone, the Tempest browser displays a similar start page with a search bar with placeholder text stating \"Search the web in total privacy.\" Below it are shortcut icons for YouTube, Netflix, Booking, Reddit, Amazon, Dribbble, Wikipedia, and an icon to access more options. At the bottom of the smartphone screen, there are navigation buttons that typically pertain to a mobile browser interface, including buttons for new tabs, bookmarks, a reading list, history, and downloads. The layouts of the browser on both devices suggest a focus on privacy for web searches and easy access to frequently visited sites through shortcuts. The Tempest logo, a stylized purple \"T\" in a speech bubble icon, is also prominently displayed on both screens.`}
                            src={ctaTeaserImageSmall}
                            className={clsx(
                                styles['cta-teaser__visual-image'],
                                {
                                    [styles['cta-teaser__visual-image__browser']]: isCustomBrowserPage,
                                },
                            )}
                        />
                    </picture>
                )}
            </div>
        </section>
    );
};

export default CtaTeaser;
