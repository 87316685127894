/**
 * # Custom Features
 */

/**
 * Imports
 */
import widgetsSrc from 'src/images/infinity/illustrations/features-widget.png';
import searchSrc from 'src/images/infinity/illustrations/features-search.png';
import privacyPanelSrc from 'src/images/infinity/illustrations/features-privacy-panel.png';
import themeSrc from 'src/images/infinity/illustrations/features-theme.png';
import clsx from 'clsx';
import FeatureCard from '../FeatureCard/FeatureCard';
import styles from './CustomFeatures.module.scss';

const CustomFeatures = () => {
    return (
        <div className={styles['wrapper']}>
            {/* Desktop */}
            <div className={clsx(styles['cards'], styles['cards--desktop'])}>
                <div className={styles['column']}>
                    <FeatureCard
                        title="Search"
                        description="Choose your preferred search engine and access advanced search functionality such as voice commands and image recognition."
                        imgSrc={searchSrc}
                        classList={styles['default-search']}
                    />
                    <FeatureCard
                        title="New tab page"
                        description="Customize your new tab page with most visited sites, bookmarks, personalized images and inspirational quotes."
                        imgSrc={themeSrc}
                        classList={clsx(styles['new-tab'], styles['pink-border'])}
                    />
                </div>

                <div className={styles['column']}>
                    <FeatureCard
                        title="Widgets"
                        description="Add convenient widgets like weather, news, social media feeds and more to your browser dashboard."
                        imgSrc={widgetsSrc}
                        classList={clsx(styles['widgets'], styles['pink-border'])}
                    />
                    <FeatureCard
                        title="Privacy panels"
                        description="Protect your privacy with incognito browsing, tracker/ad blocking, encrypted connections, and other privacy panels."
                        imgSrc={privacyPanelSrc}
                        classList={styles['privacy-panel']}
                    />
                </div>
            </div>

            {/* Mobile */}
            <div className={clsx(styles['cards'], styles['cards--mobile'])}>
                <div className={styles['column']}>
                    <FeatureCard
                        title="Search"
                        description="Choose your preferred search engine and access advanced search functionality such as voice commands and image recognition."
                        imgSrc={searchSrc}
                        classList={styles['default-search']}
                    />
                    <FeatureCard
                        title="Widgets"
                        description="Add convenient widgets like weather, news, social media feeds and more to your browser dashboard."
                        imgSrc={widgetsSrc}
                        classList={clsx(styles['widgets'], styles['pink-border'])}
                    />
                </div>

                <div className={styles['column']}>
                    <FeatureCard
                        title="New tab page"
                        description="Customize your new tab page with most visited sites, bookmarks, personalized images and inspirational quotes."
                        imgSrc={themeSrc}
                        classList={clsx(styles['new-tab'], styles['pink-border'])}
                    />
                    <FeatureCard
                        title="Privacy panels"
                        description="Protect your privacy with incognito browsing, tracker/ad blocking, encrypted connections, and other privacy panels."
                        imgSrc={privacyPanelSrc}
                        classList={styles['privacy-panel']}
                    />
                </div>
            </div>
        </div>
    );
};

export default CustomFeatures;