/**
 * # Button Close
 */
/**
 * ## Imports
 */
import React from 'react';
import clsx, { ClassValue } from 'clsx';
import styles from './ButtonClose.module.scss';

/**
 * ## Types / Interfaces
 */
type ButtonCloseProps = {
    onClick: () => void;
    classList?: string;
    title?: string;
    label?: string;
}

/**
 * ## Component Output
 */
const ButtonClose: React.FC<ButtonCloseProps> = ({ onClick, classList, title, label }) => {
    const buttonClassName: ClassValue = clsx(
        styles['button'],
        {
            [styles['button--with-label']]: label,
        },
        classList,
    );

    const labelClassName: ClassValue = clsx(
        styles['label'],
        {
            'sr-only': !label,
        },
    );

    return (
        <button
            className={buttonClassName}
            type="button"
            onClick={onClick}
            title={title}
        >
            <span className={labelClassName}>{label}</span>
        </button>
    );
};

export default ButtonClose;