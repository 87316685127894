/**
 * # CTA Teaser Intro
 */

/**
 * ## Imports
 */
import { FC } from 'react';
import clsx from 'clsx';
import { ClientName } from 'src/client/clientConfig';
import { useLocation } from 'react-router-dom';
import styles from './CtaTeaserIntro.module.scss';
import CtaButton from '../CtaButton/CtaButton';

/**
 * ## Types / Interfaces
 */
type CtaTeaserIntroProps = {
    headlineLabel: string;
    headlineQuinary?: string;
    copyText: React.ReactElement | string;
    hasButton?: boolean;
    buttonLabel?: string;
}

/**
 * ## Component
 */
const CtaTeaserIntro: FC<CtaTeaserIntroProps> = ({
    headlineLabel,
    headlineQuinary,
    copyText,
    hasButton,
    buttonLabel,
}) => {
    const location = useLocation();
    const isAboutPage = location.pathname.includes('/about');

    const renderCopyText = () => {
        if (typeof copyText === 'string') {
            return (
                // eslint-disable-next-line react/no-danger
                <p className={styles['copy-text']} dangerouslySetInnerHTML={{ __html: copyText }} />
            );
        }
        return copyText;
    };

    return (
        <div className={clsx(styles['cta-teaser-intro'], 'centerwrapper')}>
            <div>
                {headlineQuinary && <h2 className="headline-quinary">{headlineQuinary}</h2>}
                <h3
                    className={clsx(styles['headline-main'], { [styles['headline-main-about']]: isAboutPage })}
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: headlineLabel }}
                />
                {hasButton && (
                    <div className={styles['button-wrapper']}>
                        <CtaButton link="mailto:info@infinity-browsers.com" textLabel={buttonLabel} clientName={ClientName} />
                    </div>
                )}
            </div>
            <div>
                {renderCopyText()}
            </div>

        </div>
    );
};

/**
 * ## Exports
 */
export default CtaTeaserIntro;
