/**
 * # CTA Button
 */
/**
 * ## Imports
 */
import clsx, { ClassValue } from 'clsx';
import styles from './CtaButton.module.scss';

type SvgLabel = {
    src: string;
    altText: string;
}

/**
 * ## Types / Interfaces
 */
type CtaButtonProps = {
    textLabel?: string;
    link?: string;
    svgLabel?: SvgLabel;
    classList?: string;
    clientName?: string;
}

/**
 * ## Component Output
 */
const CtaButton: React.FC<CtaButtonProps> = ({
    textLabel,
    link,
    svgLabel,
    classList,
    clientName,
}) => {
    /**
     * ### Variables
     */
    const isInfinityClient = clientName === 'infinity';
    const buttonClasses: ClassValue = clsx(
        styles['cta-button'],
        {
            [styles['cta-button--disabled']]: link === undefined,
        },
        {
            [styles['cta-button--image-label']]: svgLabel,
        },
        {
            [styles['cta-button--infinity']]: isInfinityClient,
        },
        {
            [classList]: classList,
        },
    );

    return (
        (link === undefined) ? (
            <em
                className={clsx(
                    buttonClasses,
                )}
            >
                <span className={styles['cta-button__body']}>{textLabel}</span>
            </em>
        ) : (
            <a
                href={link}
                className={clsx(
                    buttonClasses,
                )}
            >
                {(svgLabel === undefined) ? (
                    <span className={styles['cta-button__body']}>{textLabel}</span>
                ) : (
                    <span className={styles['cta-button__body']}>
                        <img
                            alt={svgLabel.altText}
                            className={styles['text-vault__image']}
                            src={svgLabel.src}
                        />
                        <span className="sr-only">{svgLabel.altText}</span>
                    </span>
                )}
            </a>
        )
    );
};

export default CtaButton;