/**
 * # Page Layout
 */
/**
 * ## Imports
 */
import React, { useState, useEffect, ReactNode } from 'react';
import { useMobileMenu } from 'src/context/MobileMenuContext';
import BodyClassUpdater from 'src/helpers/bodyClassUpdater';
import { ClientName } from 'src/client/clientConfig';
import { CATEGORIESMETEOR, CATEGORIESINFINITY } from 'src/@constants/categories';
import PageHeader from '../PageHeader/PageHeader';
import PageFooter from '../PageFooter/PageFooter';
import MobileMenu from '../MobileMenu/MobileMenu';

/**
 * ## Types / Interfaces
 */
interface IPageLayoutProps {
    children: ReactNode;
}

/**
 * ## Component Output
 * 🚨 Todo: Types needed.
 */
const PageLayout: React.FC<IPageLayoutProps> = ({ children }) => {
    const naviWidthThreshold = 800;
    const isInfinityClient = ClientName === 'infinity';
    const categoryList = isInfinityClient ? CATEGORIESINFINITY : CATEGORIESMETEOR;
    /**
     * ### State
     */
    const { isMobileMenuOpen, toggleMobileMenu, closeMobileMenu } = useMobileMenu();
    const [isViewportWiderThanThreshold, setIsViewportWiderThanThreshold] = useState<boolean>(window.innerWidth <= naviWidthThreshold);

    /**
     * ### Toggling the correct menu state on resizing the browser window.
     */
    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const handleResize = () => {
            clearTimeout(timeout);

            timeout = setTimeout(() => {
                setIsViewportWiderThanThreshold(window.innerWidth <= naviWidthThreshold);
            }, 300);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <BodyClassUpdater isOpened={isMobileMenuOpen} />
            <PageHeader
                categoryList={categoryList}
                clientName={ClientName}
                isMobile={isViewportWiderThanThreshold}
                opened={isMobileMenuOpen}
                toggleOpened={toggleMobileMenu}
            />
            {isViewportWiderThanThreshold && (
                <MobileMenu
                    categoryList={categoryList}
                    hidden={!isMobileMenuOpen}
                    onClick={closeMobileMenu}
                />
            )}
            { children }
            <PageFooter categoryList={categoryList} clientName={ClientName} />
        </>
    );
};

export default PageLayout;