/**
 * # Text Vault Page Content
 */

/**
 * ## Imports
 */
import IllustrationRightOverlap from '../IllustrationRightOverlap/IllustrationRightOverlap';
import CtaButtonTextVault from '../CtaButtonTextVault/CtaButtonTextVault';
import PromoBox from '../PromoBox/Promobox';
import styles from './TextVaultPageContent.module.scss';
import textVaultIllustrationSrc from '../../images/illustrations/text_vault_illustration.png';
import textVaultIconSpeachBubble from '../../images/icons/speach_bubble.svg';
import textVaultPicture from '../../images/icons/picture.svg';
import textVaultPlus from '../../images/icons/plus.svg';
import textVaultLock from '../../images/icons/lock.svg';
import textVaultDelete from '../../images/icons/delete.svg';

/**
 * ## Component
 */
const TextVaultPageContent = () => {
    return (
        <article className={styles['text-vault']}>
            <div className={`${styles['ladyfingers']}`} />
            <div className={styles['text-vault__intro']}>
                <IllustrationRightOverlap
                    headline="Text Vault"
                    subHeadline="Send messages & photos privately"
                    paragraph="Text Vault is the leading mobile app for texting and sending photos in complete privacy. With Text Vault, you can add phone numbers easily, start conversations quickly and delete numbers when you're done. It’s all designed to keep messages off your phone and protect your real phone number. "
                    illustrationSrc={textVaultIllustrationSrc}
                    imageClassName="illustration-text-vault"
                />
                <div className="illustration-overlap-spacer">
                    <PromoBox
                        headline={
                            <span className="headline-sober">Why customers<br /> love Text Vault:</span>
                        }
                        copytext="The best app for sending private messages and managing numbers, all in one place."
                        buttonMarkup={(
                            <CtaButtonTextVault />
                        )}
                        list={[
                            {
                                bulletText: 'Separate messages from your personal number',
                                iconSrc: textVaultIconSpeachBubble,
                                iconAltText: 'Speech Bubble Icon',
                            },
                            {
                                bulletText: 'Send texts and photos instantly',
                                iconSrc: textVaultPicture,
                                iconAltText: 'Picture Icon',
                            },
                            {
                                bulletText: 'Add unlimited numbers',
                                iconSrc: textVaultPlus,
                                iconAltText: 'Plus Icon',
                            },
                            {
                                bulletText: 'Keep conversations secure right in the app',
                                iconSrc: textVaultLock,
                                iconAltText: 'Lock Icon',
                            },
                            {
                                bulletText: 'Burn numbers once you’re finished',
                                iconSrc: textVaultDelete,
                                iconAltText: 'Delete Icon',
                            },
                        ]}
                    />
                </div>
            </div>
        </article>
    );
};

/**
 * ## Exports
 */

export default TextVaultPageContent;