/**
 * # Custom Features
 */

/**
 * Imports
 */
import { ClientName } from 'src/client/clientConfig';
import clsx from 'clsx';
import CtaButton from '../CtaButton/CtaButton';
import styles from './ScheduleAMeeting.module.scss';

const ScheduleAMeeting = () => {
    return (
        <div className={styles['wrapper']}>
            <div className={clsx('centerwrapper', styles['box'])}>
                <h3 className={styles['quinary']}>Contact</h3>
                <h4 className={styles['headline']}>Schedule a meeting</h4>
                <p className={styles['copy-text']}>Schedule a meeting below with our browser specialists to discuss how our custom browser can meet your organization`s needs. We`ll demo key features and outline implementation options.</p>
                <CtaButton
                    textLabel="Meet the experts"
                    clientName={ClientName}
                    link="mailto:info@infinity-browsers.com"
                    classList={styles['cta-button']}
                />
            </div>
        </div>
    );
};

export default ScheduleAMeeting;