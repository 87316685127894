/**
 * # Cta Button Zap Scan
 * - Single source of truth for CTA buttons which are specific to Zap Scan.
 */

/**
 * ## Imports
 */
import CtaButton from '../CtaButton/CtaButton';
import appleAppStoreIcon from '../../images/logos/apple-appstore.svg';
import googlePlayStoreIcon from '../../images/logos/google-playstore.svg';

/**
 * ## Component Output
 */
const CtaButtonZapScan = () => {
    const svgLabelApple = {
        src: appleAppStoreIcon,
        altText: 'Download on the Apple App Store',
    };

    const svgLabelGoogle = {
        src: googlePlayStoreIcon,
        altText: 'Download on the Google Play Store',
    };

    return (
        <ul className="button-list">
            <li className="button-list__item">
                <CtaButton
                    link="https://apps.apple.com/ie/app/zap-scan-photo-to-pdf-scanner/id6469298180"
                    svgLabel={svgLabelApple}
                />
            </li>
            <li className="button-list__item">
                <CtaButton
                    link="https://play.google.com/store/apps/details?id=com.meteormobile.scanner"
                    svgLabel={svgLabelGoogle}
                />
            </li>
        </ul>
    );
};

/**
 * ## Exports
 */
export default CtaButtonZapScan;