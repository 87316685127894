/**
 * # Feature Card
 */

/**
 * Imports
 */
import { FC } from 'react';
import clsx from 'clsx';
import styles from './FeatureCard.module.scss';

type FeatureCardProps = {
    title: string;
    description: string;
    imgSrc: string;
    classList?: string;
}

const FeatureCard: FC<FeatureCardProps> = ({ title, description, imgSrc, classList }) => {
    return (
        <div className={clsx(styles['card'], classList)}>
            <h3 className={styles['title']}>{title}</h3>
            <p className={styles['description']}>{description}</p>
            <img alt="title" src={imgSrc} className={styles['image']} />
        </div>
    );
};

export default FeatureCard;